import { render, staticRenderFns } from "./LargeRoundImage.vue?vue&type=template&id=b1da02d6&"
import script from "./LargeRoundImage.vue?vue&type=script&lang=js&"
export * from "./LargeRoundImage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ElementsImageShape: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Elements/Image/Shape.vue').default})
