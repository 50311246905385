
import { mapState, mapMutations } from 'vuex'
import Player from '@vimeo/player'

export default {
  name: 'VimeoPlayer',
  props: {
    videoId: {
      type: String,
      required: true,
      default: '',
    },
  },
  data: () => ({
    playing: false,
    player: null,
    videoExceptionId: null,
  }),
  computed: {
    ...mapState('behaviour', {
      videoBeingPlayedId: 'videoBeingPlayedId',
    }),
    id() {
      return `vimeo-${this.videoId}-${Math.floor(Math.random() * 1000)}`
    },
  },
  watch: {
    videoBeingPlayedId(newVal) {
      if (newVal !== this.videoExceptionId) {
        if (this.player && this.videoStopCounter !== 0) {
          try {
            this.player.pause()
          } catch (e) {}
          this.onStop()
        }
      }
    },
  },
  mounted() {
    this.player = new Player(this.id, {
      id: this.videoId,
      width: this.$refs.wrapper.offsetWidth,
      height: this.$refs.wrapper.offsetHeight,
      responsive: true,
    })
    this.player.on('playing', this.onPlay)
    this.player.on('ended', this.onStop)
    this.player.on('pause', this.onStop)
  },
  methods: {
    ...mapMutations('behaviour', {
      pauseAllVideos: 'pauseAllVideos',
    }),
    play() {
      if (this.player) {
        this.videoExceptionId = new Date().getTime()
        this.pauseAllVideos(this.videoExceptionId)
        this.$emit('initiated')
        this.player.play()
      }
    },
    onPlay() {
      this.playing = true
      this.$emit('playing')
    },
    onStop() {
      this.$emit('stopped')
      this.playing = false
    },
  },
}
