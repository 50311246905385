import { render, staticRenderFns } from "./index.vue?vue&type=template&id=a24e2870&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComponentsMediaAndTextGraphicsLargeRoundImage: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Components/MediaAndText/Graphics/LargeRoundImage.vue').default,ComponentsMediaAndText: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Components/MediaAndText/index.vue').default})
