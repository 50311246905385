
export default {
  name: 'MediaAndText',
  props: {
    slice: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },
  computed: {
    themedItems() {
      return this.slice.items.map((item) => ({
        ...item,
        theme: item.buttonTheme,
      }))
    },
  },
}
