
import RoundedImageAndText from './default-slice'
import LargeRoundedImageAndText from './largeRoundedImageAndText'
import SquareImageAndText from './squareImageAndText'
import VimeoVideoAndText from './vimeoVideoAndText'
import YoutubeVideoAndText from './youtubeVideoAndText'

export default {
  name: 'MediaAndText',
  components: {
    RoundedImageAndText,
    SquareImageAndText,
    LargeRoundedImageAndText,
    VimeoVideoAndText,
    YoutubeVideoAndText,
  },
  props: {
    slice: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },
  computed: {
    component() {
      const mappings = {
        'default-slice': 'RoundedImageAndText',
        largeRoundedImageAndText: 'LargeRoundedImageAndText',
        squareImageAndText: 'SquareImageAndText',
        vimeoVideoAndText: 'VimeoVideoAndText',
        youtubeVideoAndText: 'YoutubeVideoAndText',
      }
      return mappings[this.variation]
    },
    variation() {
      return this.slice.variation
    },
  },
}
