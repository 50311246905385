
export default {
  name: 'YoutubePlayerWithThumbnail',
  props: {
    videoId: {
      type: String,
      required: true,
      default: '',
    },
    thumbnail: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
    buttonClass: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    onPlay() {
      this.loading = false
    },
    onInitiated() {
      this.loading = true
    },
    onStop() {
      this.loading = false
    },
  },
}
