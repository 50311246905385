
export default {
  name: 'PrismicButtonLinkComponentGroup',
  props: {
    data: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },
  computed: {
    isVisible() {
      return this.data?.buttonText
    },
    computedTheme() {
      return this.data.theme || 'soft-white'
    },
  },
}
