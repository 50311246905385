
export default {
  name: 'ButtonForGroups',
  props: {
    theme: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      wrapperWidth: 'auto',
    }
  },
  methods: {
    setHeadRoom() {
      const buttonWidth = this.$refs.button.$el.offsetWidth
      this.wrapperWidth = `${buttonWidth + 40}px`
    },
  },
}
